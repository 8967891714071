<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        ><strong>Nueva Venta</strong></span
      >
    </li>
  </ul>
  <div v-if="cargandoComponente">
    <div class="grid" v-if="permitirVenta">
      <div class="col-12 md:col-8">
        <div class="card">
          <h5><strong>DETALLE CLIENTE</strong></h5>
          <div class="p-fluid formgrid grid bg-gray-300">
            <div class="field col-12 md:col-4">
              <label for="cliente">SELECCIONE CLIENTE:</label>
              <div class="p-inputgroup">
                <AutoComplete
                  :dropdown="true"
                  field="nombre"
                  v-model="clienteSelect"
                  :suggestions="clientes"
                  @complete="buscarCliente($event)"
                  placeholder="Buscar Cliente"
                  v-tooltip.top="'Buscar Todos los Clientes'"
                  class="mr-1"
                />
                <Button
                  v-if="'Cliente Crear' in auth.user.permissions"
                  icon="pi pi-user"
                  class="p-button-rounded p-button-info p-button-outlined"
                  v-tooltip.top="'Nuevo Cliente'"
                  @click="activarCliente"
                />
              </div>
            </div>
            <div class="field col-12 md:col-3">
              <label for="cliente">CI/NIT:</label>
              <div class="p-inputgroup">
                <InputText
                  v-tooltip.top="
                    'Presione Enter para Buscar o  Crear Nuevo Cliente'
                  "
                  v-model="nit"
                  placeholder="0"
                  @keyup.enter="buscarClientePorNit"
                  class="mr-1"
                />
                <Button
                  v-if="'Cliente Crear' in auth.user.permissions"
                  icon="pi pi-search"
                  class="p-button-rounded p-button-info p-button-outlined"
                  v-tooltip.top="'Buscar Cliente por NIT'"
                  @click="buscarClientePorNit"
                />
              </div>
            </div>
            <div class="field col-12 md:col-5">
              <label for="cliente">NOMBRE/RAZ&Oacute;N SOCIAL:</label>
              <div class="p-inputgroup">
                <InputText v-model="razon_social" placeholder="Razón Social" />
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid mt-4">
            <div class="field col-12 md:col-5">
              <div class="p-inputgroup">
                <h5><strong>PRODUCTOS A VENDER: </strong></h5>

                <Checkbox
                  v-if="'PaqueteProducto Listar' in auth.user.permissions"
                  v-model="vender_paquete"
                  value="vender_paquete"
                  class="ml-4 mr-2"
                  v-tooltip.top="'Vender Paquete'"
                />
              </div>
            </div>
            <div class="field col-12 md:col-7">
              <div class="p-inputgroup">
                <Dropdown
                  v-if="vender_paquete == 'vender_paquete'"
                  v-model="paqueteSelected"
                  :options="paqueteproductos"
                  placeholder="Seleccione..."
                  optionLabel="nombre"
                  optionValue="id"
                  :filter="true"
                />

                <Button
                  v-if="vender_paquete == 'vender_paquete'"
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-info p-button-outlined ml-2"
                  v-tooltip.top="'Agregar Paquete al Detalle'"
                  @click="agregarProductosPaqueteAlDetalle"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
              <label for=""><strong>C&Oacute;DIGO</strong> DE PRODUCTO:</label>
              <div class="p-imputgroup">
                <AutoComplete
                  :dropdown="true"
                  field="codigo_barra"
                  v-model="codProdSelected"
                  :suggestions="codProdListadoFiltrado"
                  v-tooltip.top="'Buscar Producto por Código'"
                  @complete="buscarCodProducto($event)"
                  placeholder="Codígo Producto"
                  @keyup.enter="buscarCodProducto($event)"
                />
              </div>
            </div>
            <div class="field col-12 md:col-8">
              <label for="productos"
                ><strong>NOMBRE </strong> DE PRODUCTO:
              </label>
              <div class="p-inputgroup">
                <AutoComplete
                  :dropdown="true"
                  field="descripcion"
                  v-model="productoSelecionado"
                  :suggestions="productosListadoFiltrado"
                  @complete="buscarProducto($event)"
                  placeholder="Buscar... Nombre de Producto"
                  v-tooltip.top="'Buscar Producto por Nombre'"
                  autofocus
                  @keyup.enter="buscarProducto($event)"
                >
                  <template #item="slotProps">
                    <div>
                      <strong>{{ slotProps.item.descripcion }}</strong
                      ><span
                        ><strong> --- </strong
                        ><!-- </span>
                      {{ slotProps.item.fabricas.nombre_proveedor
                      }}<span><strong> ---</strong> --></span
                      >
                      {{ slotProps.item.fabrica_nombre
                      }}<span><strong> ---</strong></span>
                      <span
                        :class="stockClass(slotProps.item.stock[0].cantidad)"
                        ><strong>{{
                          slotProps.item.stock[0].cantidad
                        }}</strong></span
                      >
                    </div>
                  </template>
                </AutoComplete>
              </div>
            </div>
            <!--  <div class="field col-12 md:col-12">
              <DataTable
                ref="dt_stock_sucursales"
                :value="stockSucursales"
                class="p-datatable-sm"
                responsiveLayout="scroll"
                :rowhover="true"
              >
                <template #empty> Debe seleccionar un producto </template>
                <Column field="nombre_sucursal" header="Sucursal">
                  {{ data.nombre_sucursal }}
                </Column>
                <Column class="text-right" field="cantidad" header="En Stock">
                  <template #body="{ data }">
                    <div :class="stockClass(data)">
                      {{ convertirNumeroGermanicFormat(data.cantidad) }}
                    </div>
                  </template>
                </Column>

                <Column
                  class="text-right"
                  field="ultimo_precio"
                  header="Precio Venta"
                >
                  <template #body="slotProps">
                    {{
                      convertirNumeroGermanicFormat(
                        slotProps.data.ultimo_precio
                      )
                    }}
                  </template>
                </Column>
                <Column header="Acciones">
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-plus"
                      class="p-button-rounded p-button-info mr-1"
                      @click="agregarDetalleVenta(slotProps.data)"
                      v-tooltip.top="'Agregar Detalle'"
                      :disabled="slotProps.data.cantidad <= 0"
                    />
                  </template>
                </Column>
              </DataTable>
            </div> -->
          </div>
          <div class="field col-12 md:col-12">
            <DataTable
              ref="dt_venta_detalle"
              dataKey="id"
              :value="productosVentas"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              editMode="cell"
              @cell-edit-complete="guardandoCambiosCelda"
              :rowhover="true"
              stripedRows
              showGridlines
            >
              <template #empty>
                <span
                  class="flex align-items-center justify-content-center p-invalid"
                  >Busca Productos para agregar al Detalle!</span
                ></template
              >
              <Column field="nombre_producto" header="Descripción de Producto">
                {{ data.nombre_producto }}
              </Column>
              <Column
                class="text-right strong"
                field="cantidad"
                header="Existencia"
              >
                <template #body="{ data }">
                  <div :class="stockClass(data)">
                    {{ convertirNumeroGermanicFormat(data.cantidad) }}
                  </div>
                </template>
              </Column>
              <Column
                class="text-right strong"
                field="cantidad_venta"
                header="Cantidad"
              >
                <template #body="slotProps">
                  {{ slotProps.data.cantidad_venta }}
                </template>
                <template #editor="{ data, field }">
                  <InputNumber
                    v-model="data[field]"
                    input-class="text-right"
                    locale="de-DE"
                    class="p-inputtext-sm"
                    :min="1"
                    autofocus
                  />
                </template>
              </Column>
              <Column
                class="text-right strong"
                field="ultimo_precio"
                header="Precio"
              >
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(slotProps.data.ultimo_precio)
                  }}
                </template>
              </Column>
              <Column class="text-right" field="total" header="Total">
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(
                      slotProps.data.ultimo_precio *
                        slotProps.data.cantidad_venta
                    )
                  }}
                </template>
              </Column>
              <Column
                v-if="aplica_descuento == 'aplica_descuento'"
                class="text-right strong"
                field="descuento_neto"
                header="Desct. Neto"
              >
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(slotProps.data.descuento_neto)
                  }}
                </template>
                <template #editor="slotProps">
                  <InputNumber
                    :inputClass="'text-right'"
                    :min="0"
                    :minFractionDigits="2"
                    locale="de-DE"
                    v-model="slotProps.data.descuento_neto"
                  />
                </template>
              </Column>
              <Column
                v-if="aplica_descuento == 'aplica_descuento'"
                class="text-right strong"
                field="descuento_porcentaje"
                header="Desct. %"
              >
                <template #body="slotProps">
                  {{
                    convertirNumeroGermanicFormat(
                      slotProps.data.descuento_porcentaje
                    ) + "%"
                  }}
                </template>
                <template #editor="slotProps">
                  <InputNumber
                    :inputClass="'text-right'"
                    :min="0"
                    :minFractionDigits="2"
                    locale="de-DE"
                    v-model="slotProps.data.descuento_porcentaje"
                  />
                </template>
              </Column>
              <Column>
                <template #header>
                  <i class="pi pi-check"></i>
                  Entregado
                </template>
                <template #body="slotProps">
                  <Checkbox
                    v-model="slotProps.data.entregado"
                    value="entregado"
                    :disabled="slotProps.data.cantidad_venta == 0"
                    v-tooltip.top="'Entregado' == 'entregado'"
                  />
                </template>
              </Column>
              <Column class="strong">
                <template #header>
                  <i class="pi pi-cog"></i>
                  Acciones
                </template>
                <template #body="slotProps">
                  <Button
                    @click="quitarProductoDetalle(slotProps)"
                    v-tooltip.top="'Quitar Producto'"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger p-button-text"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-4">
        <div class="card">
          <Divider align="right">
            <strong>FECHA DE VENTA: </strong>{{ fechaActual(fecha_venta) }}
          </Divider>
          <h5><strong>TOTALES VENTA:</strong></h5>
          <div class="p-fluid formgrid grid">
            <table class="field col-12 md:col-12">
              <tr>
                <td>
                  <strong>SUB TOTAL:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(subtotal) }} Bs.
                </td>
              </tr>
              <tr v-if="aplica_descuento == 'aplica_descuento'">
                <td>
                  <strong>DESCUENTO:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(descuento) }} Bs.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>TOTAL VENTA:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(total_venta) }} Bs.
                </td>
              </tr>
              <tr v-if="tipo_pagoSelect == 2">
                <td>
                  <strong>A CUENTA:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(a_cuenta + monto_pago) }} Bs.
                </td>
              </tr>
              <tr v-if="tipo_pagoSelect == 2">
                <td>
                  <strong>SALDO:</strong>
                </td>
                <td class="text-right">
                  {{
                    convertirNumeroGermanicFormat(
                      total_venta - a_cuenta - monto_pago
                    )
                  }}
                  Bs.
                </td>
              </tr>
            </table>
            <Divider />
            <div class="field col-12 md:col-12">
              <strong>APLICAR DESCUENTO:</strong>
              <Checkbox
                class="ml-3 mt-1"
                value="aplica_descuento"
                :disabled="descuento > 0"
                v-model="aplica_descuento"
              />
            </div>
            <template v-if="aplica_descuento == 'aplica_descuento'">
              <table class="field col-12 md:col-12">
                <tr>
                  <td>
                    <strong>DESCUENTO NETO:</strong>
                  </td>
                  <td class="text-right">
                    <InputNumber
                      v-model="descuento"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      locale="de-DE"
                      input-class="text-right"
                      :min="0"
                      :max="subtotal"
                      @keyup="agregar_descuento_detalle_productosventas"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>DESCUENTO %:</strong>
                  </td>
                  <td class="text-right strong">
                    <InputNumber
                      v-model="desc_venta_porcentaje"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      locale="de-DE"
                      input-class="text-right"
                      :min="0"
                      :max="100"
                      @keyup="agregar_descuento_detalle_productosventas"
                    />
                  </td>
                </tr>
              </table>
            </template>
            <div class="field col-12 md:col-12">
              <table>
                <tr>
                  <td>
                    <strong>TIPO DE PAGO: </strong>
                  </td>
                  <td>
                    <Dropdown
                      id="tipo_pago"
                      v-model="tipo_pagoSelect"
                      :options="tipo_pagos"
                      optionLabel="nombre"
                      optionValue="id"
                    />
                  </td>
                </tr>
                <tr v-if="tipo_pagoSelect == 2">
                  <td>
                    <strong>D&Iacute;AS CR&Eacute;DITO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="dias_credito"
                      :min="1"
                      :max="30"
                      :inputClass="'text-right'"
                      :minFractionDigits="0"
                      locale="de-ED"
                      :useGrouping="false"
                      showButtons
                    />
                  </td>
                </tr>
                <tr v-if="tipo_pagoSelect == 2">
                  <td>
                    <strong>A CUENTA EFECTIVO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="a_cuenta"
                      :min="0"
                      :max="total_venta - monto_pago - 1"
                      :inputClass="'text-right'"
                      :minFractionDigits="2"
                      locale="de-ED"
                      :useGrouping="false"
                      showButtons
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div class="field col-12 md:col-12">
              <table>
                <tr>
                  <td>
                    <strong>M&Eacute;TODO PAGO: </strong>
                  </td>
                  <td>
                    <Dropdown
                      id="metodo_pago"
                      v-model="metodo_pagoSelect"
                      :options="metodo_pagos"
                      optionLabel="nombre"
                      class="ml-5"
                      optionValue="id"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect != 1">
                  <td>
                    <strong>MONTO PAGO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="monto_pago"
                      :min="0"
                      :max="total_venta - a_cuenta"
                      :inputClass="'text-right'"
                      :minFractionDigits="2"
                      locale="de-ED"
                      :useGrouping="false"
                      showButtons
                    />
                  </td>
                </tr>
              </table>
            </div>
            <Divider />
            <table class="field col-12 md:col-12">
              <tr>
                <td>
                  <strong>RECIBIDO:</strong>
                </td>
                <td class="text-right">
                  <InputNumber
                    v-model="efectivo"
                    :min="0"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    locale="de-DE"
                    input-class="text-right"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    <h2>CAMBIO:</h2>
                  </strong>
                </td>
                <td class="text-right p-invalid">
                  <strong>
                    <h1>{{ convertirNumeroGermanicFormat(cambio) }} Bs.</h1>
                  </strong>
                </td>
              </tr>
            </table>
          </div>
          <div class="grid justify-content-end">
            <div class="field col-12 md:col-6">
              <label for=""><strong>Factura</strong></label>
              <Checkbox
                class="ml-3 mt-1"
                value="facturar"
                v-model="facturar"
                :binary="true"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label for=""><strong>Cotizaci&oacute;n</strong></label>
              <Checkbox
                class="ml-3 mt-1"
                value="cotizar"
                v-model="cotizar"
              />
            </div>

            <Button
              label="REGISTRAR VENTA"
              icon="pi pi-save"
              v-tooltip.top="'Registrar Venta'"
              class="p-button-primary p-button-lg"
              :loading="guardando"
              @click="guardarVenta"
              :disabled="total_venta == 0 || guardando ? true : false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="grid" v-else>
      <Message class="col-12" :severity="'info'" :closable="false">
        <div class="flex align-items-center justify-content-center">
          <span> PARA REALIZAR VENTAS DEBE ABRIR LA CAJA </span>
          <Button
            label="APERTURAR CAJA"
            class="p-button-success p-button-lg ml-3"
            icon="pi pi-lock-open"
            v-tooltip.top="'Abrir Caja'"
            @click="this.$router.push({ name: 'caja' })"
          />
        </div>
      </Message>
    </div>
  </div>
  <div v-else>
    <div class="grid">
      <h3 class="col-12">Verificando caja ...</h3>
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        class="col-12"
      />
    </div>
  </div>
  <ClienteCreate
    :show="clienteModal"
    :cliente="cliente"
    @closeModal="cerrarModalCliente"
    @actualizarListado="cargarClientesListado"
  >
  </ClienteCreate>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import ProductService from "@/service/ProductService";
import VentaService from "@/service/VentaService";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import CajaService from "@/service/CajaService";
import { useAuth } from "@/stores";
import TipoPagoService from "@/service/TipoPagoService";
import MetodoPagoService from "@/service/MetodoPagoService";
import PaqueteProductoService from "@/service/PaqueteProductoService";

export default {
  components: {
    ClienteCreate,
  },
  data() {
    return {
      facturar: false,
      cotizar: false,
      guardando: false,
      clienteSelect: null,
      clientes: [],
      razon_social: null,
      nit: 0,
      productoSelecionado: null,
      productosListadoFiltrado: [],
      productosVentas: [],
      stockSucursales: [],
      subtotal: 0,
      descuento: 0,
      total_venta: 0,
      efectivo: 0,
      monto_pago: 0, //monto de pago en efectivo
      clienteModal: false,
      cliente: { estado: { label: "Activo", value: 1 } },
      fecha_venta: null,
      permitirVenta: false,
      cargandoComponente: false,
      sucursal_id: null,
      caja: null,
      codProdSelected: null,
      codProdListadoFiltrado: [],
      desc_venta_porcentaje: 0,
      aplica_descuento: false,
      tipo_pagos: [],
      tipo_pagoSelect: 1,
      metodo_pagos: [],
      metodo_pagoSelect: 1,
      dias_credito: 0,
      a_cuenta: 0,
      saldo: 0,
      stockClass: (data) => {
        if (data.cantidad <= 0) {
          return "text-red-500";
        } else if (data.cantidad <= 5) {
          return "text-yellow-500";
        } else {
          return "text-green-500";
        }
      },
      vender_paquete: false,
      paqueteSelected: null,
      paqueteproductos: [],
      paqueteproductosListado: [],
      entregado: 0,
    };
  },
  clienteService: null,
  productService: null,
  ventaService: null,
  cajaService: null,
  auth: null,
  tipoPagoService: null,
  metodoPagoService: null,
  paqueteProductoService: null,
  created() {
    this.auth = useAuth();
    this.clienteService = new ClienteService();
    this.productService = new ProductService();
    this.ventaService = new VentaService();
    this.cajaService = new CajaService();
    this.tipoPagoService = new TipoPagoService();
    this.metodoPagoService = new MetodoPagoService();
    this.paqueteProductoService = new PaqueteProductoService();
  },
  mounted() {
    this.verificarCajaAbierta();
    this.cargarClientesListado();
    this.obtenerTiposPagos();
    this.obtenerMetodosPagos();
    this.obtenerPaquetes();
  },
  computed: {
    // si mi venta es al credito entonces en efectivo se registra el monto  a cuenta el cambio es el total efectivo - a cuenta
    cambio() {
      if (this.tipo_pagoSelect == 2) {
        return this.efectivo - this.a_cuenta;
      } else {
        return this.efectivo + this.monto_pago - this.total_venta;
      }
    },
  },
  methods: {
    obtenerPaquetes() {
      this.paqueteProductoService.getPaqueteProductoAll().then((response) => {
        this.paqueteproductos = response.paqueteproductos;
      });
    },
    obtenerTiposPagos() {
      this.tipoPagoService.getAllTipoPagos().then((response) => {
        this.tipo_pagos = response;
      });
    },
    obtenerMetodosPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response;
      });
    },
    buscarClientePorNit() {
      //BUSCO EL CLIENTE POR NIT Y SI NO EXISTE LO CREO
      if (this.nit != null) {
        this.clienteService
          .buscarClientePorNit({ nit: this.nit })
          .then((response) => {
            if (response.cliente != null) {
              this.$toast.add({
                severity: "success",
                summary: "Cliente encontrado",
                detail: "Se encontro el cliente",
                life: 3000,
              });
              this.clienteSelect = response.cliente;
            } else {
              //PREPARO MIS DATOS PARA CREAR UN NUEVO CLIENTE
              this.$toast.add({
                severity: "info",
                summary: "Cliente no encontrado",
                detail: "Se creara un nuevo cliente",
                life: 3000,
              });
              this.cliente.razon_social = this.razon_social;
              this.cliente.nit = this.nit;
              this.cliente_id = this.clienteSelect.id;
            }
          });
      }
    },

    aplicar_descuento_productos_detalle() {
      this.productosVentas.forEach((producto) => {
        producto.descuento_porcentaje = this.desc_venta_porcentaje;
        producto.descuento_neto =
          producto.ultimo_precio *
          producto.cantidad_venta *
          (producto.descuento_porcentaje / 100);
      });
      this.calcular_subtotal();
    },
    buscarCliente(event) {
      setTimeout(() => {
        this.clienteService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.clientes = [...response.clientes];
          });
      }, 250);
    },
    verificarCajaAbierta() {
      this.cajaService.verificarCajaAbierta().then((response) => {
        if (response.caja.length > 0) {
          this.caja = response.caja[0];
          this.sucursal_id = response.caja[0].sucursal_id;
          this.permitirVenta = true;
        } else {
          this.permitirVenta = false;
        }

        this.cargandoComponente = true;
      });
    },

    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },

    guardarVenta() {
      this.guardando = true;
      // recorro el array de productosVentas y verifico si la cantidad de venta es mayor a ceero
      let cantidadVenta = 0;
      this.productosVentas.forEach((producto) => {
        cantidadVenta += producto.cantidad_venta;
      });
      if (cantidadVenta == 0) {
        this.guardando = false;
        this.$toast.add({
          severity: "error",
          summary: "Venta",
          detail: "Debe ingresar cantidad de venta",
          life: 3000,
        });
        return;
      }

      let datos = {
        razon_social: this.razon_social,
        nit: this.nit,
        cliente_id: this.clienteSelect.id,
        total_cancelar: this.total_venta,
        descuento: this.descuento,
        efectivo: this.efectivo,
        productos: this.productosVentas,
        caja_id: this.caja.id,
        tipo_pago_id: this.tipo_pagoSelect,
        metodo_pago_id: this.metodo_pagoSelect,
        monto_pago: this.monto_pago,
        dias_credito: this.dias_credito,
        a_cuenta: this.a_cuenta,
        cambio: this.cambio,
        facturado: this.facturar ? 1 : 0,
      };

      //fecha_venta: this.fecha_venta,

      if (this.productosVentas.length > 0) {
        this.ventaService
          .crear(datos)
          .then((res) => {
            if (res.status == 400 || res.mensaje) {
              this.guardando = false;
              this.$toast.add({
                severity: "info",
                summary: "Venta",
                detail: res.mensaje,
                life: 8000,
              });
              return;
            }

            this.$toast.add({
              severity: "success",
              summary: "Venta",
              detail: "Venta Realizada con Exito",
              life: 3000,
            });
            this.guardando = false;
            this.$router.push({
              name: "venta_detalle",
              params: { id: res.venta.id },
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Venta",
              detail: "Error de conexion",
              life: 3000,
            });
            this.guardando = false;
          });
      } else {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail: "No hay productos en la venta",
          life: 3000,
        });
        this.guardando = false;
      }
    },
    activarCliente() {
      this.clienteModal = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerrarModalCliente() {
      this.clienteModal = false;
      this.cliente = {};
    },
    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;

      if (field == "cantidad_venta") {
        if (data.cantidad_venta > data.cantidad) {
          data.cantidad_venta = data.cantidad;
          this.$toast.add({
            severity: "info",
            summary: "Venta",
            detail: "No hay suficiente stock",
            life: 3000,
          });
        }
      }

      if (field == "cantidad_venta") {
        data["total"] = newValue * data["ultimo_precio"];
        this.calcular_subtotal();
      }
      if (field == "descuento_neto" && data["cantidad_venta"] > 0) {
        data["descuento_porcentaje"] = (newValue * 100) / data["total"];
        this.calcular_descuentos_totales_venta();
      }
      if (field == "descuento_porcentaje" && data["cantidad_venta"] > 0) {
        data["descuento_neto"] = (newValue * data["total"]) / 100;
        this.calcular_descuentos_totales_venta();
      }
    },
    calcular_descuentos_totales_venta() {
      let desc_venta_neto = 0;
      let desc_venta_porcentaje = 0;
      this.productosVentas.forEach((producto) => {
        desc_venta_neto += producto.descuento_neto;
        desc_venta_porcentaje += producto.descuento_porcentaje;
      });
      this.descuento = desc_venta_neto;
      this.desc_venta_porcentaje = desc_venta_porcentaje;
    },
    calcular_subtotal() {
      this.subtotal = 0;
      this.productosVentas.forEach((product) => {
        //console.log(this.subtotal);
        this.subtotal += product.total;
        //console.log(product.total);
      });
      this.total_venta = this.subtotal - this.descuento;
    },
    agregar_descuento_detalle_productosventas() {
      this.productosVentas.forEach((producto) => {
        producto.descuento_neto =
          (this.descuento * producto.total) / this.subtotal;
        producto.descuento_porcentaje =
          (producto.descuento_neto * 100) / producto.total;
      });
    },
    calcular_total_venta() {
      this.total_venta = this.subtotal - this.descuento;
    },
    calcular_descuento_venta_porcentaje() {
      this.desc_venta_porcentaje = (this.descuento * 100) / this.subtotal;
    },
    calcular_descuento_venta_neto() {
      this.descuento = (this.desc_venta_porcentaje * this.subtotal) / 100;
    },
    quitarProductoDetalle(datos) {
      this.$confirm.require({
        message: `¿Está seguro de quitar el producto ?`,
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.productosVentas.splice(datos.index, 1);
          this.calcular_subtotal();
        },
      });
    },
    buscarCodProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXCodProducto({ texto: event.query })
          .then((response) => {
            this.codProdListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 100);
    },
    cargarClientesListado() {
      this.clienteService
        .getClientesAll()
        .then((response) => {
          this.clientes = response;
          // recorro los cliente y crgo al select el menor id
          this.clientes.forEach((cliente) => {
            // busco el cliente CONTROL TRIBUTARIO 99002 y lo cargo al select por defecto al cargar la pagina
            if (cliente.id == 1) {
              this.clienteSelect = cliente;
            }
          });
        })
        .catch((error) => console.log(error));
    },
    agregarDetalleVenta(data) {
      if (this.productosVentas.length > 0) {
        let existe = this.productosVentas.find(
          (producto) => producto.id == data.id
        );
        if (existe) {
          this.$toast.add({
            severity: "info",
            summary: "Venta",
            detail: "El producto ya se encuentra en la venta",
            life: 3000,
          });
          return;
        }
      }
      if (data.ultimo_precio == 0) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail: "El producto no tiene un precio asignado",
          life: 3000,
        });
        return;
      }
      let precio =
        data.ultimo_precio == 0 ? data.precio_sugerido : data.ultimo_precio;
      this.productosVentas.push({
        cantidad_venta: 1,
        descuento_neto: 0,
        descuento_porcentaje: 0,
        ultimo_precio: precio,
        total: precio,
        ...data,
      });
      this.stockSucursales = [];
      this.productoSelecionado = null;
      this.productosListadoFiltrado = [];

      this.calcular_subtotal();
    },
    agregarProductosPaqueteAlDetalle() {
      let datos = {
        paquete_producto_id: this.paqueteSelected,
      };
      this.paqueteProductoService
        .getPaqueteProductoById(datos)
        .then((response) => {
          response.paqueteproductosListado.forEach((producto) => {
          
            // obtengo el precio del producto 
            let precio =
              producto.ultimo_precio == 0
                ? producto.precio_sugerido
                : producto.ultimo_precio;

            this.productosVentas.push({
              cantidad_venta: producto.cantidad,
              descuento_neto: 0,
              descuento_porcentaje: 0,
              ultimo_precio: precio,
              total: precio * producto.cantidad,
              ...producto,
            });
          });
        })
        .catch((error) => console.log(error));
    },
    fechaActual() {
      return new Date().toLocaleDateString();
    },
  },
  watch: {
    clienteSelect(datos) {
      this.razon_social = datos.nombre;
      this.nit = datos.ci_nit;
    },
    productoSelecionado(newVal) {
      if (newVal != null) {
        if (newVal.id) {
          //this.productosVentas.push(newVal);
          this.stockSucursales = [];
          newVal.stock.forEach((sucursal) => {
            let precio_sugerido =
              sucursal.ultimo_precio == 0
                ? newVal.precio_sugerido
                : sucursal.ultimo_precio;
            this.stockSucursales.push({
              ...sucursal,
              ultimo_precio: parseFloat(precio_sugerido),
              cantidad_venta: 1,
              descuento_neto: 0,
              descuento_porcentaje: 0,
            });
            // automaticamente selecciono la sucursal con el menor id y llamo a mi funcion agregarDetalleVenta
            if (sucursal.id == this.stockSucursales[0].id) {
              this.agregarDetalleVenta(this.stockSucursales[0]);
            }
          });
        }
      }
    },
    codProdSelected(newVal) {
      if (newVal != null) {
        if (newVal.id) {
          //this.productosVentas.push(newVal);
          this.stockSucursales = [];
          newVal.stock.forEach((sucursal) => {
            let precio_sugerido =
              sucursal.ultimo_precio == 0
                ? newVal.precio_sugerido
                : sucursal.ultimo_precio;
            this.stockSucursales.push({
              ...sucursal,
              ultimo_precio: parseFloat(precio_sugerido),
              cantidad_venta: 0,
              descuento_neto: 0,
              descuento_porcentaje: 0,
            });
            // automaticamente selecciono la sucursal con el menor id y llamo a mi funcion agregarDetalleVenta
            if (sucursal.id == this.stockSucursales[0].id) {
              this.agregarDetalleVenta(this.stockSucursales[0]);
            }
            this.codProdSelected = null;
          });
        }
      }
    },
    descuento() {
      this.calcular_total_venta();
      this.calcular_descuento_venta_porcentaje();
      /* this.agregar_descuento_detalle_productosventas(); */
    },
    desc_venta_porcentaje() {
      this.calcular_descuento_venta_neto();
    },
    total_venta() {
      this.efectivo = this.total_venta;
    },
    a_cuenta() {
      if (this.a_cuenta > this.total_venta) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail: "El monto a cuenta no puede ser mayor al total de la venta",
          life: 3000,
        });
        this.a_cuenta = 0;
        return;
      }
      this.efectivo = this.a_cuenta;
    },
    monto_pago() {
      if (this.tipo_pagoSelect == 2) {
        this.efectivo = this.a_cuenta;
      } else {
        this.efectivo = this.total_venta - this.monto_pago;
      }
    },
    tipo_pagoSelect() {
      if (this.tipo_pagoSelect == 1) {
        this.a_cuenta = 0;
        this.monto_pago = 0;
        this.dias_credito = 0;
        this.efectivo = this.total_venta;
      } else {
        this.dias_credito = 1;
        this.a_cuenta = 0;
        this.metodo_pagoSelect = 1;
        this.monto_pago = 0;
        this.efectivo = this.a_cuenta;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}

::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

.outofstock {
  font-weight: 700;
  color: #ff5252;
  text-decoration: line-through;
}

.lowstock {
  font-weight: 700;
  color: #ffa726;
}

.instock {
  font-weight: 700;
  color: #66bb6a;
}
</style>
